import React, { FC, ReactElement } from 'react';
import * as Ink from '@carta/ink';
import Header from '../Header/Header';
import StyledLink from '../Common/StyledLink';

interface TopProps {
  breadcrumbLinks?: { to: string; children: ReactElement }[];
  componentName?: string;
  subtitle: string;
  title: string;
  viewSourceHref: string;
}

const Top: FC<TopProps> = ({ breadcrumbLinks, componentName, subtitle, title, viewSourceHref }) => {
  let actions = [
    {
      label: 'View source',
      type: 'btn',
      href: viewSourceHref,
    },
    {
      label: 'Contribute',
      type: 'primary',
      href: 'https://github.com/carta/ink/blob/master/CONTRIBUTING.md',
    },
  ];

  if (componentName) {
    actions = [
      {
        label: 'See component documentation',
        type: 'btn',
        href: `/components/${componentName}`,
      },

      ...actions,
    ];
  }

  return (
    <>
      <Ink.Box bottom="medium">
        {breadcrumbLinks?.map(({ children, to }, index) => (
          <>
            {index > 0 ? ' / ' : ''}
            <StyledLink {...{ children, to }} />
          </>
        ))}
      </Ink.Box>

      <Header
        title={
          <Ink.HStack alignY="center" spacing="small">
            {title}
            <Ink.Box display="flex">
              <Ink.Bubble type="purple">Preview</Ink.Bubble>
            </Ink.Box>
          </Ink.HStack>
        }
        // TODO: Refactor span as an ink component if possible
        subtitle={<span style={{ wordBreak: 'break-all' }}>{subtitle}</span>}
        {...{ actions }}
      />
    </>
  );
};

export default Top;
