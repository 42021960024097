import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby';
import * as Ink from '@carta/ink';
import Content from '../../../components/Content/Content';
import Layout from '../../../components/Layout';
import Top from '../../../components/Usage/Top';

const UsagePagesPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [uniquePageViewsCount, setUniquePageViewsCount] = useState(0);
  const [usageData, setUsageData] = useState({});

  useEffect(() => {
    fetch(withPrefix('datasets/pages-2023-03-20.json'))
      .then(response => response.json())
      .then(data => {
        const sumOfPageViews = data.pages.reduce((accumulator, currentPage) => accumulator + +currentPage.count, 0);

        setUniquePageViewsCount(sumOfPageViews);
        setUsageData(data);
        setIsLoading(false);
      });
  }, []);

  function getHealth(n) {
    const great = [3, 22, 31, 38];
    const decent = [14];
    const poor = [0, 6, 7, 17, 21, 27, 30, 41];
    const veryPoor = [10, 11, 16, 18, 24, 26, 35];
    const needAccess = [19, 32];
    let label = '—';
    let variant = 'feedback-neutral';

    if (![...great, ...decent, ...poor, ...veryPoor, ...needAccess].includes(n)) return;

    if (great.includes(n)) {
      label = 'Great';
      variant = 'feedback-positive';
    }

    if (decent.includes(n)) {
      label = 'Decent';
      variant = 'feedback-notice';
    }

    if (poor.includes(n)) {
      label = 'Poor';
      variant = 'feedback-notice';
    }

    if (veryPoor.includes(n)) {
      label = 'Very poor';
      variant = 'feedback-negative';
    }

    if (needAccess.includes(n)) {
      label = 'Need access';
      variant = 'feedback-neutral';
    }

    return <Ink.Status isCell isMini {...{ label, variant }} />;
  }

  return (
    <Layout pageTitle="Usage / Components">
      <Top
        breadcrumbLinks={[{ children: 'Usage', to: '/usage' }]}
        title="Pages"
        subtitle="Unique page views for the week ending March 20, 2023"
        viewSourceHref="https://github.com/carta/ink/blob/master/docs/src/usage/pages/index.tsx"
      />

      <Content>
        {isLoading && <Ink.Loader isLoading type="page" />}

        {!isLoading && (
          <Ink.VStack>
            <Ink.Box top="xlarge">
              <Ink.Heading1>
                {`${new Intl.NumberFormat().format(
                  uniquePageViewsCount ?? 0,
                )} unique page views for March 14–20, 2023 across ${new Intl.NumberFormat().format(
                  usageData.pages.length ?? 0,
                )} pages`}
              </Ink.Heading1>

              <Ink.Paragraph>
                <i>Note</i>: Of the 9,986 pages listed, 6,913 are login URLs, which means <strong>3,073</strong> are
                actual in-app URLs.
              </Ink.Paragraph>

              <Ink.Box top="large">
                <Ink.HStack>
                  <Ink.Box maxWidth="100%" minWidth={['none', '600px']}>
                    <Ink.Box top="gutter">
                      <Ink.NewTable>
                        <Ink.NewTable.Head>
                          <Ink.NewTable.Row>
                            <Ink.NewTable.HeadCell align="right">Rank</Ink.NewTable.HeadCell>
                            <Ink.NewTable.HeadCell>Page</Ink.NewTable.HeadCell>
                            <Ink.NewTable.HeadCell>Health</Ink.NewTable.HeadCell>
                            <Ink.NewTable.HeadCell align="right">Unique views</Ink.NewTable.HeadCell>
                          </Ink.NewTable.Row>
                        </Ink.NewTable.Head>

                        <Ink.NewTable.Body>
                          {usageData.pages
                            .sort((n1, n2) => n2.count - n1.count)
                            .map((page, index) => {
                              const isLoginUrl = page.url.includes('https://login.app.carta.com');

                              return (
                                <Ink.NewTable.Row key={page.url} bgColor={isLoginUrl ? 'gray-lightest-1' : undefined}>
                                  <Ink.NewTable.Cell align="right">
                                    {new Intl.NumberFormat().format(index + 1)}.
                                  </Ink.NewTable.Cell>

                                  <Ink.NewTable.Cell>
                                    {/* TODO: Refactor div as an ink component */}
                                    <div
                                      style={{
                                        maxWidth: `600px`,
                                        overflowX: `hidden`,
                                        textOverflow: `ellipsis`,
                                      }}
                                    >
                                      <Ink.Anchor href={page.url} rel="noreferrer noopener" target="_blank">
                                        {page.url}
                                      </Ink.Anchor>
                                    </div>
                                  </Ink.NewTable.Cell>

                                  <Ink.NewTable.Cell>{getHealth(index)}</Ink.NewTable.Cell>

                                  <Ink.NewTable.Cell align="right">
                                    {new Intl.NumberFormat().format(page.count ?? 0)}
                                  </Ink.NewTable.Cell>
                                </Ink.NewTable.Row>
                              );
                            })}
                        </Ink.NewTable.Body>
                      </Ink.NewTable>
                    </Ink.Box>
                  </Ink.Box>
                </Ink.HStack>
              </Ink.Box>
            </Ink.Box>
          </Ink.VStack>
        )}
      </Content>
    </Layout>
  );
};

export default UsagePagesPage;
